<template>
    <div class="col-right">
        <a-spin :spinning="spinning">
            <a-tabs default-active-key="2" :tabBarGutter="0" @change="changeTab"
                    :tabBarStyle="{backgroundColor: '#fff', marginBottom: '16px', borderBottom: 'none', padding: '6px 20px'}">
                <a-tab-pane key="1" tab="交易数据">
                    <a-card>
                        <a-row class="header">
                            <a-col span="8" class="flex">
                                <h3>核心数据</h3>
                                <a-icon type="cloud-download"/>
                                <a-icon type="clock-circle"/>
                            </a-col>
                            <a-col span="16">
                                <div class="date-row">
                                    <span class="ng-star-inserted" v-for="item in dayTypeList.orderStat" :key="item.key"
                                          @click="changeDayType(item.key)">
                                        <a class="date-btn ant-btn ant-btn-link ant-btn-sm"
                                           :class="queryParams.dayType == item.key ? 'selected' : ''">
                                            <span class="ng-star-inserted">{{item.title}}</span>
                                        </a>
                                    </span>
                                    <div>
                                        <div>
                                            <a-range-picker v-if="[1, 2].indexOf(queryParams.dayType) >= 0"
                                                            disabled/>
                                            <a-date-picker v-if="queryParams.dayType == 3" @change="changeDate"
                                                           :value="currentDate" value-format="YYYY-MM-DD"/>
                                            <a-week-picker v-if="queryParams.dayType == 4" @change="changeWeek"
                                                           value-format="YYYY-W"
                                                           :value="currentWeek"
                                                           :locale = "locale"/>
                                            <a-month-picker v-if="queryParams.dayType == 5" @change="changeMonth"
                                                            value-format="YYYY-MM" :default-value="currentMonth"/>
                                            <a-range-picker v-if="queryParams.dayType == 6" @change="changeRange"
                                                            value-format="YYYY-MM-DD"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row :gutter="40">
                            <a-col :span="8">
                                <a-card class="stat" :bodyStyle="{padding: '20px 24px 8px'}">
                                    <a-space direction="vertical" style="width: 100%;">
                                        <div class="g2-card__top">
                                            <div class="g2-card__avatar"></div>
                                            <div
                                                    class="g2-card__meta-wrap">
                                                <div class="g2-card__meta"><span
                                                        class="g2-card__meta-title ng-star-inserted">采购金额（元）</span><span
                                                        class="g2-card__meta-action ng-star-inserted">
                              <a-icon type="question-circle"/>
                          </span></div>
                                                <p
                                                        class="g2-card__total ng-star-inserted">¥{{ orderAmount }}</p>
                                            </div>
                                        </div>
                                        <div class="g2-card__desc">
                                            <div class="g2-card__fixed"> 环比前7天
                                                <div class="trend"><span
                                                        class="ng-star-inserted trend__up">
                                <a-icon type="caret-up"/>
                            </span></div>
                                                <a style="color: #000;"
                                                   class="ant-btn ant-btn-link"><span
                                                        class="ng-star-inserted">100%</span></a></div>
                                        </div>
                                    </a-space>
                                </a-card>
                            </a-col>
                            <a-col :span="8">
                                <a-card class="stat" :bodyStyle="{padding: '20px 24px 8px'}">
                                    <a-space direction="vertical">
                                        <div class="g2-card__top">
                                            <div class="g2-card__avatar"></div>
                                            <div
                                                    class="g2-card__meta-wrap">
                                                <div class="g2-card__meta"><span
                                                        class="g2-card__meta-title ng-star-inserted">采购订单数</span><span
                                                        class="g2-card__meta-action ng-star-inserted">
                              <a-icon type="question-circle"/>
                          </span></div>
                                                <p
                                                        class="g2-card__total ng-star-inserted">{{ orderNumber }}</p>
                                            </div>
                                        </div>
                                        <div class="g2-card__desc">
                                            <div class="g2-card__fixed"> 环比前7天
                                                <div class="trend"><span
                                                        class="ng-star-inserted trend__up">
                                <a-icon type="caret-up"/>
                            </span></div>
                                                <a style="color: #000;" class="ant-btn ant-btn-link"><span
                                                        class="ng-star-inserted">100%</span></a></div>
                                        </div>
                                    </a-space>
                                </a-card>
                            </a-col>
                            <a-col :span="8">
                                <a-card class="stat" :bodyStyle="{padding: '20px 24px 8px'}">
                                    <a-space direction="vertical">
                                        <div class="g2-card__top">
                                            <div class="g2-card__avatar"></div>
                                            <div
                                                    class="g2-card__meta-wrap">
                                                <div class="g2-card__meta"><span
                                                        class="g2-card__meta-title ng-star-inserted">采购商品数</span><span
                                                        class="g2-card__meta-action ng-star-inserted">
                              <a-icon type="question-circle"/>
                          </span></div>
                                                <p
                                                        class="g2-card__total ng-star-inserted">{{ orderGoods }}</p>
                                            </div>
                                        </div>
                                        <div class="g2-card__desc">
                                            <div class="g2-card__fixed"> 环比前7天
                                                <div class="trend"><span
                                                        class="ng-star-inserted trend__up">
                                <a-icon type="caret-up"/>
                            </span></div>
                                                <a style="color: #000;"
                                                   class="ant-btn ant-btn-link"><span
                                                        class="ng-star-inserted">100%</span></a></div>
                                        </div>
                                    </a-space>
                                </a-card>
                            </a-col>
                        </a-row>

                        <echart echartId="orderChart" :options="chartOptions"></echart>
                    </a-card>
                    <a-card>
                        <a-row class="header">
                            <a-col span="8" class="flex">
                                <h3>商品采购单</h3>
                                <a-icon type="question-circle"/>
                                <a-icon type="cloud-download"/>
                                <a-icon type="clock-circle"/>
                            </a-col>
                            <a-col span="16">
                                <div class="date-row">
                                    <span class="ng-star-inserted" v-for="item in dayTypeList.goodsStat" :key="item.key"
                                          @click="changeGoodsDayType(item.key)">
                                        <a class="date-btn ant-btn ant-btn-link ant-btn-sm"
                                           :class="goodsQueryParams.dayType == item.key ? 'selected' : ''">
                                            <span class="ng-star-inserted">{{item.title}}</span>
                                        </a>
                                    </span>
                                    <div>
                                        <div>
                                            <a-range-picker :value="goodsCurrentRangeDate" @change="changeGoodsDate" value-format="YYYY-MM-DD"/>
                                        </div>
                                    </div>
                                </div>
                            </a-col>
                        </a-row>

                        <a-table :columns="buyerGoodsColumns" :data-source="buyerGoodsList" :scroll="{x:true}">
                            <div slot="order" slot-scope="text, record">{{record.order.length}}</div>
                            <div slot="sort" slot-scope="text, record, index">{{ index + 1 }}</div>
                        </a-table>
                    </a-card>
                </a-tab-pane>
                <a-tab-pane key="2" tab="商品数据">
                    <a-card>
                        <a-row class="header">
                            <a-col span="8" class="flex">
                                <h3>商品采购金额类目占比</h3>
                                <a-icon type="question-circle"/>
                                <a-icon type="cloud-download"/>
                                <a-icon type="clock-circle"/>
                            </a-col>
                            <a-col span="16">
                                <div class="date-row">
                                    <span class="ng-star-inserted" v-for="item in dayTypeList.goodsCateStat" :key="item.key"
                                          @click="changeGoodsCateDayType(item.key)">
                                        <a class="date-btn ant-btn ant-btn-link ant-btn-sm"
                                           :class="goodsCateQueryParams.dayType == item.key ? 'selected' : ''">
                                            <span class="ng-star-inserted">{{item.title}}</span>
                                        </a>
                                    </span>
                                    <div>
                                        <div>
                                            <a-range-picker :value="goodsCateCurrentRangeDate" @change="changeGoodsCateDate" value-format="YYYY-MM-DD"/>
                                        </div>
                                    </div>
                                </div>
                            </a-col>
                        </a-row>

                        <echart echartId="goods-cate-echart" :options="goodsCateEchartOptions"></echart>
                    </a-card>
                    <a-card>
                        <a-row class="header">
                            <a-col span="8" class="flex">
                                <h3>商品动销趋势</h3>
                                <a-icon type="cloud-download"/>
                                <a-icon type="clock-circle"/>
                            </a-col>
                            <a-col span="16">
                                <div class="date-row">
                                    <span class="ng-star-inserted" v-for="item in dayTypeList.goodsSaleStat" :key="item.key"
                                          @click="changeGoodsSaleStatDayType(item.key)">
                                        <a class="date-btn ant-btn ant-btn-link ant-btn-sm"
                                           :class="goodsSaleStatQueryParams.dayType == item.key ? 'selected' : ''">
                                            <span class="ng-star-inserted">{{item.title}}</span>
                                        </a>
                                    </span>
                                    <div>
                                        <div>
                                            <a-range-picker v-if="[1, 2].indexOf(goodsSaleStatQueryParams.dayType) >= 0"
                                                            :value="goodsSaleStatCurrentRangeDate"
                                                            disabled/>
                                            <a-date-picker v-if="goodsSaleStatQueryParams.dayType == 3" @change="goodsSaleStatChangeDate"
                                                           :value="goodsSaleStatCurrentRangeDate[0]" value-format="YYYY-MM-DD"/>
                                            <a-week-picker v-if="goodsSaleStatQueryParams.dayType == 4" @change="goodsSaleStatChangeWeek"
                                                           value-format="YYYY-W"
                                                           :value="currentWeek"
                                                           :locale = "locale"/>
                                            <a-month-picker v-if="goodsSaleStatQueryParams.dayType == 5" @change="goodsSaleStatChangeMonth"
                                                            value-format="YYYY-MM" :default-value="currentMonth"/>
                                            <a-range-picker v-if="goodsSaleStatQueryParams.dayType == 6" @change="goodsSaleStatChangeRange"
                                                            value-format="YYYY-MM-DD"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </a-col>
                        </a-row>
                         <echart echartId="goods-sale-stat-echart" :options="goodsSaleStatEchartOptions"></echart>
                    </a-card>
                </a-tab-pane>
                <a-tab-pane key="3" tab="售后数据">
                    Content of Tab Pane 3
                </a-tab-pane>

            </a-tabs>
        </a-spin>
    </div>
</template>

<script>
    import moment from 'moment';

    import service from "../../../utils/request";
    import echart from "@/component/echart";
    import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

    export default {
        name: "board",
        components: {
            echart
        },
        data() {
            return {
                locale,
                spinning: false,
                activeKey: 1,
                buyerGoodsColumns: [
                    {title: '排名', dataIndex: 'sort', key: 'sort', scopedSlots: {customRender: 'sort'},},
                    {title: '商品名称', dataIndex: 'goods_title', key: 'goods_title'},
                    {title: '商品编号', dataIndex: 'goods_id', key: 'goods_id'},
                    {
                        title: '采购订单数',
                        dataIndex: 'order',
                        key: 'order',
                        scopedSlots: {customRender: 'order'},
                        defaultSortOrder: 'descend'
                    },
                    {
                        title: '采购件数',
                        dataIndex: 'goods_number',
                        key: 'goods_number',
                        defaultSortOrder: 'descend',
                        sorter: (a, b) => a.goods_number - b.goods_number,
                    },
                    {
                        title: '采购金额(元)',
                        dataIndex: 'goods_amount',
                        key: 'goods_amount',
                        defaultSortOrder: 'descend',
                        sorter: (a, b) => a.goods_amount - b.goods_amount,
                    },
                ],
                buyerGoodsList: [],
                buyGoodsCateList: [],
                dayTypeList: {
                    orderStat:[
                        {key: 1, title: '近7天'},
                        {key: 2, title: '近30天'},
                        {key: 3, title: '日'},
                        {key: 4, title: '周'},
                        {key: 5, title: '月'},
                        {key: 6, title: '自定义'},
                    ],
                    goodsStat:[
                        {key: 1, title: '昨天'},
                        {key: 2, title: '近7天'},
                        {key: 3, title: '近30天'},
                        {key: 4, title: '上周'},
                        {key: 5, title: '上月'},
                    ],
                    goodsCateStat:[
                        {key: 1, title: '昨天'},
                        {key: 2, title: '近7天'},
                        {key: 3, title: '近30天'},
                        {key: 4, title: '上周'},
                        {key: 5, title: '上月'},
                    ],
                    goodsSaleStat:[
                        {key: 1, title: '近7天'},
                        {key: 2, title: '近30天'},
                        {key: 3, title: '日'},
                        {key: 4, title: '周'},
                        {key: 5, title: '月'},
                        {key: 6, title: '自定义'},
                    ],
                },
                queryParams: {
                    dayType: 1,
                },
                goodsQueryParams: {
                    dayType: 1,
                },
                goodsCateQueryParams: {
                    dayType: 1,
                },
                goodsSaleStatQueryParams: {
                    dayType: 1,
                },
                orderAmount: 0,
                orderNumber: 0,
                orderGoods: 0,
                orderAmountStat: [],
                orderNumberStat: [],
                chartOptions: {},
                legendData: ['采购金额', '采购订单数', '采购商品量'],
                xAxisData: [],
                currentDate: '',
                currentWeek: '',
                currentMonth: '',
                range_date: [],
                goodsRangeDate: [],
                goodsCateRangeDate: [],
                goodsSaleStatRangeDate: [],
                goodsCurrentRangeDate:[],
                goodsCateCurrentRangeDate:[],
                goodsSaleStatCurrentRangeDate:[],
                goodsCateEchartOptions:{},
                goodsSaleStatEchartOptions:{},
            }
        },
        created() {
            this.changeDayType(1);
            this.changeGoodsDayType(1);
            this.changeGoodsCateDayType(1);
            this.changeGoodsSaleStatDayType(1);
        },
        methods: {
            moment,
            changeTab(e) {
                console.log(e)
            },
            changeDate(e) {
                this.currentDate = e;
                this.getDateStat(e);
            },
            goodsSaleStatChangeDate(e){
                this.goodsSaleStatCurrentRangeDate = [e, e];
                this.goodsSaleStatQueryParams.goodsRangeDate = this.goodsSaleStatCurrentRangeDate;

                this.spinning = true;
                service.post(service.uri.goods.goodsSaleStat, this.goodsSaleStatQueryParams).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.resetGoodsSaleStatEchart(res.data);
                    }
                });
            },
            changeWeek(e) {
                console.log(e)
                this.currentWeek = e;
                this.getDateStat(e);
            },
            changeMonth(e) {
                this.getDateStat(e);
            },
            goodsSaleStatChangeMonth(e){
                this.goodsSaleStatQueryParams.goodsRangeDate = e;

                this.spinning = true;
                service.post(service.uri.goods.goodsSaleStat, this.goodsSaleStatQueryParams).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.resetGoodsSaleStatEchart(res.data);
                    }
                });
            },
            goodsSaleStatChangeRange(e){
                this.goodsSaleStatChangeMonth(e);
            },
            changeRange(e) {
                this.getDateStat(e);
            },
            getDateStat(rangeDate) {
                this.spinning = true;
                service.post(service.uri.order.stat, {
                    range_date: rangeDate,
                    dayType: this.queryParams.dayType
                }).then(res => {
                    this.spinning = false;
                    if (res.code == 200) {
                        this.orderAmount = res.data.order_amount;
                        this.orderNumber = res.data.order_num;
                        this.orderGoods = res.data.order_goods;
                        this.xAxisData = res.data.date_list;
                        this.chartOptions = {
                            tooltip: {
                                trigger: 'axis'
                            },
                            legend: {
                                data: this.legendData,
                            },
                            xAxis: {
                                data: this.xAxisData,
                            },
                            yAxis: {},
                            series: [
                                {
                                    name: "采购金额",
                                    type: "line",
                                    data: res.data.order_amount_stat,
                                },
                                {
                                    name: "采购订单数",
                                    type: "line",
                                    data: res.data.order_number_stat,
                                },
                                {
                                    name: "采购商品量",
                                    type: "line",
                                    data: res.data.order_goods_stat,
                                },
                            ],
                        };
                    }
                });
            },
            changeGoodsDayType(dayType){
                this.goodsQueryParams.dayType = dayType;
                this.goodsCurrentRangeDate = this.goodsRangeDate[dayType];
                this.goodsQueryParams.goodsRangeDate = this.goodsCurrentRangeDate;
                this.spinning = true;
                service.post(service.uri.order.goodsStat, this.goodsQueryParams).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.buyerGoodsList = res.data.list;
                        this.goodsRangeDate = res.data.goods_range_date;
                        this.goodsCurrentRangeDate = this.goodsRangeDate[dayType];
                    }
                });
            },
            changeGoodsCateDayType(dayType){
                this.goodsCateQueryParams.dayType = dayType;
                this.goodsCateCurrentRangeDate = this.goodsCateRangeDate[dayType];
                this.goodsCateQueryParams.goodsRangeDate = this.goodsCateCurrentRangeDate;
                this.spinning = true;
                service.post(service.uri.goods.goodsCateStat, this.goodsCateQueryParams).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.buyGoodsCateList = res.data.list;
                        this.goodsCateRangeDate = res.data.goods_range_date;
                        this.goodsCateCurrentRangeDate = this.goodsRangeDate[dayType];
                        this.resetGoodsCateEchart(res.data);
                    }
                });
            },
            changeGoodsSaleStatDayType(dayType){
                this.goodsSaleStatQueryParams.dayType = dayType;
                this.goodsSaleStatCurrentRangeDate = this.goodsSaleStatRangeDate[dayType];
                this.goodsSaleStatQueryParams.goodsRangeDate = this.goodsSaleStatCurrentRangeDate;
                this.spinning = true;
                service.post(service.uri.goods.goodsSaleStat, this.goodsSaleStatQueryParams).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.goodsSaleStatRangeDate = res.data.goods_range_date;
                        this.goodsSaleStatCurrentRangeDate = this.goodsSaleStatRangeDate[dayType];
                        this.resetGoodsSaleStatEchart(res.data);
                    }
                });
            },
            resetGoodsSaleStatEchart(data){
                let ratio = [];
                data.list.goods_num.forEach((item, idx) => {
                    ratio.push(parseFloat(data.list.sale_num[idx] / item).toFixed(2));
                })
                this.goodsSaleStatEchartOptions = {
                    tooltip:{
                        trigger: 'axis'
                    },
                    legend: { },
                    xAxis:{
                        type:'category',
                        data: data.list.date,
                    },
                    yAxis: {},
                    series:  [
                        {
                            name: '商品库商品数',
                            data: data.list.goods_num,
                            type: 'bar'
                        },
                        {
                            name:'动销商品数',
                            data: data.list.sale_num,
                            type: 'bar'
                        },
                        {
                            name:'商品动销率',
                            data: ratio,
                            type: 'line'
                        }
                    ]
                };
            },
            resetGoodsCateEchart(data){
                this.goodsCateEchartOptions = {
                    title: {
                        text: '采购金额（元）',
                        textStyle:{
                            fontSize:'14',
                            fontWeight:'normal'
                        },
                        subtext:data.total,
                        subtextStyle :{
                            fontSize: '16',
                            fontWeight:'bold'
                        },
                        left: 'center',
                        top: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        right: '0',
                        orient: 'vertical'
                    },
                    series:  [
                        {
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'top'
                            },
                            data: data.list
                        }
                    ]
                };
            },
            changeGoodsDate(e){
                this.goodsCurrentRangeDate = e;
                this.spinning = true;
                service.post(service.uri.order.goodsStat, {goodsRangeDate: this.goodsCurrentRangeDate}).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.buyerGoodsList = res.data.list;
                    }
                });

            },
            changeGoodsCateDate(e){
                this.goodsCateCurrentRangeDate = e;
                this.spinning = true;
                service.post(service.uri.goods.goodsCateStat, {goodsRangeDate: this.goodsCateCurrentRangeDate}).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.buyGoodsCateList = res.data.list;
                        this.resetGoodsCateEchart(res.data);
                    }
                });

            },
            changeDayType(dayType) {
                this.queryParams.dayType = dayType;
                if (dayType == 3) {
                    this.getDateStat(this.currentDate);
                    return false;
                }else if(dayType == 5) {
                    this.getDateStat(this.currentMonth);
                    return false;
                }else if(dayType == 6) {
                    this.getDateStat(this.range_date);
                    return false;
                }
                this.spinning = true;
                service.post(service.uri.order.stat, this.queryParams).then(res => {
                    this.spinning = false;
                    if (res.code == 200) {
                        this.currentDate = res.data.last_date;
                        this.currentWeek = res.data.current_week;
                        this.currentMonth = res.data.current_month;
                        this.range_date = res.data.range_date;
                        this.orderAmount = res.data.order_amount;
                        this.orderNumber = res.data.order_num;
                        this.orderGoods = res.data.order_goods;
                        this.xAxisData = res.data.date_list;
                        this.chartOptions = {
                            tooltip: {
                                trigger: 'axis'
                            },
                            legend: {
                                data: this.legendData,
                            },
                            xAxis: {
                                data: this.xAxisData,
                            },
                            yAxis: {},
                            series: [
                                {
                                    name: "采购金额",
                                    type: "line",
                                    data: res.data.order_amount_stat,
                                },
                                {
                                    name: "采购订单数",
                                    type: "line",
                                    data: res.data.order_number_stat,
                                },
                                {
                                    name: "采购商品量",
                                    type: "line",
                                    data: res.data.order_goods_stat,
                                },
                            ],
                        };
                    }
                });
            },
        }
    }
</script>

<style lang="less" scoped>


    .flex {
        display: flex;
        align-items: center;
    }

    .header {
        margin-bottom: 32px;

        h3 {
            margin-right: 10px;
        }

        i {
            margin: 0 6px;
            color: #418dfd;
            font-size: 18px;
        }
    }

    .date-row {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;

        .date-btn {
            margin: 0 10px;
            padding: 2px 5px;
            color: #000;
        }

        .ant-btn > i, .ant-btn > span {
            display: inline-block;
            pointer-events: none;
        }

        .selected {
            background-color: #de0000;
            color: #fff;
        }
    }

    .ant-spin-container {
        position: relative;
        transition: opacity .3s;

    }

    .g2-card__top {
        width: 100%;
        overflow: hidden;
    }

    .g2-card__meta {
        height: 22px;
        color: rgba(0, 0, 0, .45);
        font-size: 14px;
        line-height: 22px;

        .g2-card__meta-action {
            position: absolute;
            top: 4px;
            right: 0;
            line-height: 1;
            cursor: pointer;
        }

        .anticon {
            display: inline-block;
            color: inherit;
            font-style: normal;
            line-height: 0;
            text-align: center;
            text-transform: none;
            vertical-align: -0.125em;
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    .g2-card, .g2-card__top {
        position: relative;
    }

    .g2-card__total {
        height: 38px;
        margin-top: 4px;
        margin-bottom: 0;
        overflow: hidden;
        color: rgba(0, 0, 0, .85);
        font-size: 30px;
        line-height: 38px;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
    }

    .g2-card__desc {
        width: 100%;
        margin-bottom: 12px;
    }

    .trend {
        display: inline-block;
        font-size: 14px;
        line-height: 22px;

        .trend__up {
            color: #f5222d;
        }
    }

    .stat.ant-card-bordered {
        box-shadow: rgba(204, 204, 204, 0.2) 0 0 4px 4px;
    }

    .ant-table td {
        white-space: nowrap;
    }
</style>