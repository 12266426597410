<template>
    <div :id="echartId" style="width: 100%;" :style="{height: height}"></div>
</template>

<script>
    export default {
        name: "echart",
        data(){
            return {
                chart: null,
            }
        },
        props: {
            height: {
                type: String,
                default: '400px'
            },
            legendData:{type: Array},
            seriesData:{type: Array},
            xAxisData:{type: Array},
            options:{},
            echartId:{
                type: String, default: 'main'
            }
        },
        watch:{
            options(val){
                if(this.chart){
                    this.options ? this.chart.setOption(val) : this.chart.clear();
                }
            }
        },
        mounted() {
            this.drawChart();
        },
        methods: {
            drawChart() {
                // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
                this.chart = this.$echarts.init(document.getElementById(this.echartId));
                // 指定图表的配置项和数据
                // this.options = {
                //     tooltip: {
                //         trigger: 'axis'
                //     },
                //     legend: {
                //         data: this.legendData,
                //     },
                //     xAxis: {
                //         data: this.xAxisData,
                //     },
                //     yAxis: {},
                //     series: this.seriesData,
                // };
                // 使用刚指定的配置项和数据显示图表。
                this.chart.setOption(this.options);
            },

        }
    }
</script>

<style scoped>

</style>